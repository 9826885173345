import type { NuxtError } from '#app'
import { rpcCall } from '@scayle/storefront-nuxt'
import type { LoggingParams } from '~/rpcMethods/logging'

const NUXT_ERROR_SIGNATURE = '__nuxt_error'

const isNuxtError = (input: any): input is NuxtError =>
  !!input && typeof input === 'object' && NUXT_ERROR_SIGNATURE in input

const getMessage = (input: LoggingParams['message']) => {
  if (typeof input === 'string') {
    return input
  }

  if (isNuxtError(input)) {
    return input.message
  }

  if (input instanceof Error) {
    return input.message
  }

  return ''
}

const getExtras = (
  input: LoggingParams['message'],
  extras: LoggingParams['extras'],
) => {
  const res = typeof extras === 'string' ? { extras } : { ...extras }

  if (isNuxtError(input!)) {
    return {
      ...res,
      data: input.data,
      statusCode: input.statusCode,
      statusMessage: input.statusMessage,
      fatal: input.fatal,
      unhandled: input.unhandled,
    }
  }

  if (input instanceof Error) {
    return {
      ...res,
      name: input.name,
    }
  }

  return Object.keys(res).length ? { ...res } : undefined
}

export const useLogging = () => {
  const nuxtApp = useNuxtApp()
  const currentShop = useCurrentShop()

  const method = (
    level: LoggingParams['level'],
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) =>
    rpcCall(
      nuxtApp,
      'logMessage',
      currentShop.value,
    )({
      message: getMessage(message),
      extras: getExtras(message, extras),
      level,
    })

  const log = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) => method('log', message, extras)

  const warn = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) => method('warn', message, extras)

  const error = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) => method('error', message, extras)

  const debug = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) => method('debug', message, extras)

  const table = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) => method('table', message, extras)

  const logger = { log, warn, error, debug, table }

  return {
    logger,
  }
}
